import React, { useLayoutEffect } from 'react';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { ReactSVG } from 'react-svg';
import { TopMenuStyle } from './style';
import Cheflist from '../components/Cheflist';

function TopMenu() {
  const { path } = useRouteMatch();

  useLayoutEffect(() => {
    const active = document.querySelector('.strikingDash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };
  return (
    <TopMenuStyle>
        <div className="strikingDash-top-menu" style={{fontSize:'12px',fontWeight: '900px' , marginLeft: '60px',marginTop:'3px'}}>
        <ul>
        <li>
        <Link to={`${path}/ChefList/ChefListDataTable`} className="parent">
            Manage Chef 
            </Link>
            </li>
      
            <li>
        <Link to={`${path}/Customerlist/CustomerlistDataTable`} className="parent">
            Customer
            </Link>
            </li>

            <li>
        <Link to={`${path}/Orderlist/OrderlistDataTable`} className="parent">
            Order List
            </Link>
            </li>
            <li>
        <Link to={`${path}/Projectgigs/Projectlist`} className="parent">
            Project List
            </Link>
            </li>
            <li>
        <Link to={`${path}/CouponPages/Couponlist`} className="parent">
        Promo code List
            </Link>
            </li>
            <li>
        <Link to={`${path}/Feedback/ChefFeedback`} className="parent">
           Reviews
            </Link>
            </li>
            <li>
        <Link to={`${path}/Menulist`} className="parent">
           Posted Menu
            </Link>
            </li>
            {/* <li>
            <Link to={`${path}/Addtax/Addtax`} className="parent">
            Add Tax
            </Link>
            </li> */}
            <li>
        
            </li>
              
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;

import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload,Checkbox ,notification, Divider} from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main ,NotificationListWrapper} from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import Heading from '../../components/heading/heading';
 import  CryptoJS from 'crypto-js';
import axios from 'axios';


function Adminupdate() {
  let Detail=JSON.parse(localStorage.getItem("Detail"))
  let [name,setName]=useState(Detail.Name)
  let[data,setData]= useState([]);
  let[done,setDone]= useState([]);
  let [lastname,setlastname]=useState(Detail.lname)
  let [email,setEmail]=useState(Detail.Email)
  let [phone,setphone]=useState(Detail.Phone)
  let [finalimg,setFinalimg]=useState()
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  var dataaa = JSON.stringify({ [process.env.REACT_APP_API_KEY]: process.env.REACT_APP_API_VALUE });
  var dataencryptdescrypkey=process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
  var encrypted = CryptoJS?.AES?.encrypt(dataaa, dataencryptdescrypkey).toString();
  let imagepath = `${process.env.REACT_APP_IMGPATH}/`;
  //let imagepath = `${API_ENDPOINT}/getimage/`;
  let [pic,setPic]=useState(imagepath+ Detail.profilepic)
  
  localStorage.setItem("adminprofile",pic)
//console.log(pic)
 
  let URL1 = '/updateuser'
const addNotificationSuccess = (msg) => {
  notification.success({
    description: msg,
  });
};
  const Adminprofile=()=>{
    let getid=Detail.id
    let token=localStorage.getItem("DeviceToken")
    
    let formdata= new FormData();
    formdata.append('_id',getid)
    formdata.append('profilePic',finalimg)
    formdata.append('lastName',lastname)
    formdata.append('email',email)
    formdata.append("firstName",name)
    formdata.append("phone",phone)
    // 
    
    axios({
      url:API_ENDPOINT + URL1, 
        method:"POST",
        headers:{
          "Content-Type": "application/json",
        "userauthorization": token,
        apikey:encrypted,

        },data:formdata


      })
      .then(res=>{
        console.log(res.data)
        setData(res.data)
        setDone(res);
           
      })
      .catch(res=>{
        console.log("error")
        
      })
   }


  const [state, setState] = useState({
    values: '',
  });
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    setState({ ...state, values });
  };
  const handlepic=(e)=>{
    setPic(URL.createObjectURL(e.target.files[0]))
    if(e.target && e.target.files[0]){
    setFinalimg(e.target.files[0])
    console.log(e.target.files[0])
  
  }
  }
  const validateMessages = {
    types: {
      city: '',
      state: '',
      zip: '',
      checkbox: '',
    },
  };
  function onChange() {}
  const check=()=>{
    if(data.length==0){
      return 0;
    }
   else if(done.status==200)
    {
     
      let myObj = { 'Name': data.data.firstName, 'lname':data.data.lastName,'Email': data.data.email, 'Phone': data.data.phone,'id':data.data._id,"profilepic":data.data.profilePic}
      localStorage.setItem('Detail', JSON.stringify(myObj))
      //alert(done.data.message)
     
      addNotificationSuccess(done.data.message);
    }
    
  }
  useEffect(()=>{
    check()
  },[done])
  return (
    <>
      <PageHeader
        ghost
        
      />
      <Main>
        <Row gutter={25} justify="center" >
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards title="Profile Update">
                  <Form name="sDash_validation-form" form={form} layout="vertical" validateMessages={validateMessages}>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="f-name"
                          label="First Name" initialValue={name}
                          rules={[{ required: true, message: 'First Name required!' }]}
                        >
                          <Input placeholder="First Name" onChange={(e)=>(setName(e.target.value))} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="l-name"
                          label="Last Name" initialValue={lastname}
                          rules={[{ required: true, message: 'Last Name required!' }]}
                        >
                          <Input placeholder="Last Name" onChange={(e)=>(setlastname(e.target.value))} />
                        </Form.Item>
                      </Col>
                      </Row>
                      <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="email"
                          label="Email Address" initialValue={email}
                          rules={[{ required: true, message: 'Email required!' }]}
                        >
                         <Input placeholder="name@example.com" onChange={(e)=>(setEmail(e.target.value))} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                      <Form.Item
                          name="phone"
                          label="Phone Number" initialValue={phone}
                          rules={[{ required: true, message: 'Phone number required!' }]}
                        >
                        <Input placeholder="+440 2546 5236" onChange={(e)=>(setphone(e.target.value))} />
                        </Form.Item>
                      </Col>
                      </Row>
                      <Row gutter={30}>
                      <Col md={8} xs={24}>
                      <div className='Adminimage'>
                    
                      <input type="file" class="profileimg" id="img" accept="image/png, image/gif, image/jpeg" onChange={handlepic}/>
                      <label for="img" class="uploadimg">Upload image</label>
                {/* <img src={pic}/> */}
                     
                <img
                        
                        src={
                          pic
                            ? pic
                            : require('../../static/img/profile.png')
                        }
                      /> 
                </div>
                 
                
                      </Col>
                      {/* <Col md={8} xs={24}>
                        <Form.Item name="zip-code" label="Zip" rules={[{ type: 'zip' }]}>
                          <Input placeholder="Zip" />
                        </Form.Item>
                      </Col> */}
                    </Row>
                    
                    <div className="sDash_form-action mt-20">
                      <Button htmlType="submit" type="primary" size="large" onClick={Adminprofile} className='blk-btn'>
                        Update
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>

          
        </Row>
      </Main>
    </>
  );
}

export default Adminupdate;

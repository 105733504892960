import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './dashboard';
import Ecommerce from './ecommerce';
import Pages from './pages';
import Users from './users';
import Widgets from './widgets';
import Features from './features';
import Axios from './axios';
import Gallery from './gallery';
import withAdminLayout from '../../layout/withAdminLayout';

const Projects = lazy(() => import('./projects'));
const Calendars = lazy(() => import('../../container/Calendar'));
const Inbox = lazy(() => import('../../container/email/Email'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const Firebase = lazy(() => import('./firebase'));
const ToDo = lazy(() => import('../../container/toDo/ToDo'));
const Note = lazy(() => import('../../container/note/Note'));
const Contact = lazy(() => import('../../container/contact/Contact'));
const ContactGrid = lazy(() => import('../../container/contact/ContactGrid'));
const ContactAddNew = lazy(() => import('../../container/contact/AddNew'));
const Calendar = lazy(() => import('../../container/calendar/Calendar'));
// const FileManager = lazy(() => import('../../container/fileManager/FileManager'));
const Kanban = lazy(() => import('../../container/kanban/Index'));
const Task = lazy(() => import('../../container/task/Index'));

const RestrictedWord = lazy(() => import('../../container/Addfood/RestrictedWord.js'));
const cheflist = lazy(() => import('../../container/ChefList/ChefListDataTable'));
const customerlist = lazy(() => import('../../container/Customerlist/CustomerlistDataTable'));
const orderlist = lazy(() => import('../../container/Orderlist/OrderlistDataTable'));
const OrderlistForm = lazy(() => import('../../container/Orderlist/overview/OrderlistForm'));
const changepwd = lazy(() => import('../../container/passwordchange/Changepassword'));




const adminupdate = lazy(() => import('../../container/adminupdate/Adminupdate'));
const Foodtable = lazy(() => import('../../container/Addfood/Foodtable'));
const addtax = lazy(() => import('../../container/Addtax/Addtax'));
const SalesData = lazy(() => import('../../container/Addtax/SalesData'));
const EmiData = lazy(() => import('../../container/Addtax/emiData'));


const chefdetail = lazy(() => import('../../container/ChefList/ChefDetails'));
const chefedit = lazy(() => import('../../container/ChefList/ChefEdit'));
const customerdetail = lazy(() => import('../../container/Customerlist/CustomerDetails'));
const Profile = lazy(() => import('../../container/ChefList/overview/Profile'));
const Biography = lazy(() => import('../../container/ChefList/overview/Biography'));
const Specaility = lazy(() => import('../../container/ChefList/overview/Specaility'));
const StipeTable = lazy(() => import('../../container/GetStripe/StipeTable'));
const ChefFeedback = lazy(() => import('../../container/Feedback/ChefFeedback'));
const Projectgigs = lazy(() => import('../../container/projectgigs/Projectlist'));
const Coupon = lazy(() => import('../../container/CouponPages/Couponlist'));

const ProjectForm = lazy(() => import('../../container/projectgigs/overview/ProjectForm'));
const CouponForm = lazy(() => import('../../container/CouponPages/overview/CouponForm'));
const CouponEdit = lazy(() => import('../../container/CouponPages/overview/CouponEdit'));
const Menutable = lazy(() => import('../../container/Managemenu/Addmenu'));
const Menucattable = lazy(() => import('../../container/Managemenu/AddMenucat'));
const Menuadd =  lazy(() => import('../../container/Menuadd/Menuadd'));
const Menulist = lazy(() => import('../../container/projectgigs/Menulist'));
function Admin() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/ecommerce`} component={Ecommerce} />
        <Route path={`${path}`} component={Pages} />
        <Route path={`${path}`} component={Features} />
        <Route path={`${path}`} component={Axios} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/gallery`} component={Gallery} />
        <Route path={`${path}/project`} component={Projects} />
        <Route path={`${path}/calendar`} component={Calendars} />        
        <Route path={`${path}/app/kanban`} component={Kanban} />
        <Route path={`${path}/email/:page`} component={Inbox} />
        <Route path={`${path}/firestore`} component={Firebase} />
        <Route path={`${path}/main/chat`} component={Chat} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />
        <Route path={`${path}/app/to-do`} component={ToDo} />
        <Route path={`${path}/app/note`} component={Note} />
        <Route path={`${path}/app/task`} component={Task} />
        <Route path={`${path}/contact/list`} component={Contact} />
        <Route path={`${path}/contact/grid`} component={ContactGrid} />
        <Route path={`${path}/contact/addNew`} component={ContactAddNew} />
        <Route path={`${path}/app/calendar`} component={Calendar} />
        <Route path={`${path}/widgets`} component={Widgets} />
        <Route path={`${path}/ChefList/ChefListDataTable`} component={cheflist} />    
        <Route path={`${path}/Customerlist/CustomerlistDataTable`} component={customerlist} />
        <Route path={`${path}/Orderlist/OrderlistDataTable`} component={orderlist} />
        {/* <Route path={`${path}/Orderlist/OrderlistDataTable`} component={OrderlistDataTable} /> */}
        <Route path={`${path}/Orderlist/overview/OrderlistForm`} component={OrderlistForm} />
        <Route path={`${path}/passwordchange/Changepassword`} component={changepwd} />
        <Route path={`${path}/adminupdate/Adminupdate`} component={adminupdate} />
        <Route path={`${path}/Addfood/Foodtable`} component={Foodtable} />        
        <Route path={`${path}/Addtax/Addtax`} component={addtax} />
        <Route path={`${path}/SalesData`} component={SalesData} />
        <Route path={`${path}/EmiData`} component={EmiData} />
        
        <Route path={`${path}/ChefList/ChefDetails`} component={chefdetail} />
        <Route path={`${path}/ChefList/ChefEdit`} component={chefedit} />
        <Route path={`${path}/Customerlist/CustomerDetails`} component={customerdetail} />
        <Route exact path={`${path}/ChefList/overview/Profile`} component={Profile} />
        <Route exact path={`${path}/ChefList/overview/Biography`} component={Biography} />
        <Route exact path={`${path}/ChefList/overview/Specaility`} component={Specaility} />
        <Route exact path={`${path}/GetStripe/StipeTable`} component={StipeTable} />
        <Route exact path={`${path}/Feedback/ChefFeedback`} component={ChefFeedback} />
        <Route path={`${path}/Projectgigs/Projectlist`} component={Projectgigs} />
        <Route path={`${path}/CouponPages/Couponlist`} component={Coupon} />
        {/* <Route path={`${path}/Projectgigs/ProjectForm/:id`} component={ProjectForm} /> */}
        <Route path={`${path}/Projectgigs/ProjectForm`} component={ProjectForm} />
        <Route path={`${path}/CouponPages/CouponForm`} component={CouponForm} />
        <Route path={`${path}/CouponPages/CouponEdit`} component={CouponEdit} />


        <Route path={`${path}/Addfood/RestrictedWord`} component={RestrictedWord} />
        <Route exact path={`${path}/Managemenu/Addmenu`} component={Menutable} />
        <Route exact path={`${path}/Managemenu/AddMenucat`} component={Menucattable} />
        <Route exact path={`${path}/Menuadd/Menuadd`} component={Menuadd} />
        <Route path={`${path}/Menulist`} component={Menulist} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);

import React, { useState } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
// import Message from './message';
// import Notification from './notification';
// import Settings from './settings';
// import Support from './support';
import { Button, Popover } from 'antd';
// import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
import Heading from '../../heading/heading';
import {useRouteMatch } from 'react-router-dom';
import Adminupdate from '../../../container/adminupdate/Adminupdate';
function AuthInfo() {
  let profilepic= localStorage.getItem("adminprofile");
  //let imagepath = "https://chezyoubackendv2.makemagento.com/api/v1/getimage/"
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let imagepath = `${API_ENDPOINT}/getimage/`;
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid,
    };
  });
  const [flags,setflags]= useState(false)
 const hide = () => {
   setflags(false)
  };

const  handleVisibleChange = (open )=> {
    setflags(open)
  };
  const [state, setState] = useState({
    flag: 'english',
  });
  const { flag } = state;
  

   const SignOut = e => {
    e.preventDefault();
    if (isAuthenticate) {
      dispatch(fbAuthLogout(dispatch(logOut())));
    } else {
      dispatch(logOut());
    }
  };

  const styles = {
    // color: 'red',
    backgroundColor: '#ffff',
    position: 'fixed',
    right: '1px',
    top:'70px',
    width:'210px',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', // Adjust the values to customize the shadow
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  };
  const userContent = (
    <UserDropDwon>
    <div className='right-menu'>
      <div className="user-dropdwon">
        {/* <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">Abdullah Bin Talha</Heading>
          </figcaption>
        </figure> */}

        <ul className="user-dropdwon__links">
          <li>
          <Link to= {`${path}/adminupdate/Adminupdate`} >
            <FeatherIcon icon="user" />Profile
            </Link>
          </li>
          <li>
            {/* <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link> */}
            <Link to= {`${path}/passwordchange/Changepassword`} >
            <FeatherIcon icon="settings" />Change Password
            </Link>
          </li>
          <li>
            <Link to={`${path}/Addtax/Addtax`}>
              <FeatherIcon icon="dollar-sign" /> Tax Management
            </Link>
          </li>
          <li>
            <Link to={`${path}/SalesData`}>
              <FeatherIcon icon="dollar-sign" /> SalesData Management
            </Link>
          </li>
          <li>
            <Link to={`${path}/emiData`}>
              <FeatherIcon icon="dollar-sign" /> EmiData Management
            </Link>
          </li>

          {/* <li>
            <Link to={`${path}/GetStripe/StipeTable`}>
              <FeatherIcon icon="settings" /> Strip Settings
            </Link>
          </li> */}
          <li>
            <Link to={`${path}/Addfood/Foodtable`}>
              <FeatherIcon icon="pie-chart" /> Food
            </Link>
          </li>
          <li>
            <Link to={`${path}/Addfood/RestrictedWord`}>
              <FeatherIcon icon="scissors" /> Restricted word
            </Link>
          </li>
          <li>
            <Link to={`${path}/Managemenu/Addmenu`}>
              <FeatherIcon icon="tag" />Menu Category
            </Link>
          </li>
          <li>
            <Link to={`${path}/Menuadd/Menuadd`}>
              <FeatherIcon icon="plus-square" />Create Menu 
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      {/* <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link> */}
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        {/* <Popover placement="bottomRight" 
        
        
        content={<a onClick={hide}>{userContent}</a>}
        title="Title"
        trigger="click"
        visible={flags}
        onVisibleChange={handleVisibleChange}
        open={flags}
      onOpenChange={handleVisibleChange}
        // content={userContent}
        //  action="click"
         >
          <Link to="#" className="head-example">
       
       <Avatar src={profilepic} />
          </Link>
        </Popover> */}

<Popover
      content={<a onClick={hide}>{ <UserDropDwon>
        <div className='right-menu'style={styles}>
          <div className="user-dropdwon">
            {/* <figure className="user-dropdwon__info">
              <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
              <figcaption>
                <Heading as="h5">Abdullah Bin Talha</Heading>
               
              </figcaption>
            </figure> */}
            <ul className="user-dropdwon__links">
              <li>
              <Link to= {`${path}/adminupdate/Adminupdate`} >
                <FeatherIcon icon="user" />Profile
                </Link>
              </li>
              <li>
                {/* <Link to="#">
                  <FeatherIcon icon="settings" /> Settings
                </Link> */}
                
                <Link to= {`${path}/passwordchange/Changepassword`} >
                <FeatherIcon icon="settings" />Change Password
                </Link>
              </li>

              <li>
                <Link to={`${path}/Addtax/Addtax`}>
                  <FeatherIcon icon="dollar-sign" /> Tax Management
                </Link>
              </li>

              <li>
                <Link to={`${path}/SalesData`}>
                  <FeatherIcon icon="dollar-sign" /> SalesData Management
                </Link>
              </li>
              <li>
                <Link to={`${path}/emiData`}>
                  <FeatherIcon icon="dollar-sign" /> EmiData Management
                </Link>
              </li>


              
              {/* <li>
                <Link to={`${path}/GetStripe/StipeTable`}>
                  <FeatherIcon icon="settings" /> Strip Settings
                </Link>
              </li> */}
              <li>
                <Link to={`${path}/Addfood/Foodtable`}>
                  <FeatherIcon icon="pie-chart" /> Food
                </Link>
              </li>
              <li>
                <Link to={`${path}/Addfood/RestrictedWord`}>
                  <FeatherIcon icon="scissors" /> Restricted word
                </Link>
              </li>
              <li>
                <Link to={`${path}/Managemenu/Addmenu`}>
                  <FeatherIcon icon="tag" /> Menu Category
                </Link>
              </li>
              {/* <li>
                <Link to={`${path}/Managemenu/AddMenucat`}>
                  <FeatherIcon icon="tag" />Menu Category
                </Link>
              </li> */}
              <li>
                <Link to={`${path}/Menuadd/Menuadd`}>
                  <FeatherIcon icon="plus-square" />Create Menu 
                </Link>
              </li>
            </ul>
            <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
              <FeatherIcon icon="log-out" /> Sign Out
            </Link>
          </div>
        </div>
        </UserDropDwon>}</a>}
      // title="Title"
      trigger="click"
      open={flags}
      onOpenChange={handleVisibleChange}
    >
      <Link to="#" className="head-example">
       
       <Avatar src={profilepic} />
          </Link>
    </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
